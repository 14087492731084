import Rest from '@/services/Rest';

/**
 * @typedef {BillingAvailableGenerate}
 */
export default class BillingAvailableGenerate extends Rest {
    /**
     * @type {String}
     */
    static resource = '/uau/billing-generate'
}
