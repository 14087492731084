<template>
    <div class="financial">
        <div class="col-12 px-0 pt-5 scrolling" style="margin-top: 50px">
            <div class="d-flex p-dash-cliente-master-l">
                <div class="pr-0 align-self-center">
                    <div class="col-12">
                        <div class="row">
                            <div class="col-12 pr-0">
                                <router-link :to="{name: 'Dashboard'}" class="btn btn-success rounded-circle btn-circle font-20 card-body-list-cta">
                                    <i class="fas fa-arrow-left"></i>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="pr-0 d-flex cta-dash-cliente" style="max-width: 300px;">
                    <div class="card col-12 p-0">
                        <div class="card-body">
                            <a @click="getBillings" style="cursor: pointer;">
                                <div>
                                    <div>
                                        <img src="../../assets/img/icons/financeiro.png" height="25" alt="Qsuíte">
                                        <h2 class="pt-2 pb-0 mb-0 font-weight-medium title-dash-cliente">Boleto</h2>
                                        <span>Emita 2ª via de boletos</span>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
                <div class="pr-0 d-flex cta-dash-cliente" style="max-width: 300px;">
                    <div class="card col-12 p-0">
                        <div class="card-body">
                            <a @click="getReport" style="cursor: pointer;">
                                <div>
                                    <div>
                                        <img src="../../assets/img/icons/financeiro.png" height="25" alt="Qsuíte">
                                        <h2 class="pt-2 pb-0 mb-0 font-weight-medium title-dash-cliente">Informe de
                                            rendimentos</h2>
                                        <span>Consulte o Informe de Rendimentos para declarar no IR</span>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
                <div class="d-flex cta-dash-cliente p-dash-cliente-master-r" style="max-width: 300px;">
                    <div class="card col-12 p-0">
                        <div class="card-body">
                            <a @click="getExtract" style="cursor: pointer;">
                                <div>
                                    <div>
                                        <img src="../../assets/img/icons/financeiro.png" height="25" alt="Qsuíte">
                                        <h2 class="pt-2 pb-0 mb-0 font-weight-medium title-dash-cliente">Extrato
                                            consolidado</h2>
                                        <span>Os saldos acima são baseados nas informações disponíveis até esse
                                            instante</span>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container-fluid p-dash-cliente-master pb-2 pt-3">

            <template v-if="showBillings">
                <div class="col-12 pb-2">
                    <h3 class="page-title text-truncate text-dark font-weight-medium mb-1">Boletos</h3>
                </div>
                <div class="col-12">
                    <div class="row">
                        <div class="col-12">
                            <div class="card-list-legenda">
                                <div class="card-body-list-legend d-none d-lg-block">
                                    <div class="col-12 p-0 align-self-center">
                                        <div class="col-12">
                                            <div class="row">
                                                <div
                                                    class="col-3 col-md-auto col-lg-1 align-self-center text-left order-2 order-md-2 order-lg-2 pad-content-list-img">
                                                    <p class="text-truncate title-legend-list" style="width:45px">&nbsp;
                                                    </p>
                                                </div>
                                                <div class="col-7 col-md-6 col-lg-9 p-0 order-3 align-self-center">
                                                    <div class="col-12">
                                                        <div class="row">
                                                            <div
                                                                class="col-12 col-md-12 col-lg-4 align-self-center pad-content-list">
                                                                <p class="text-truncate title-legend-list">Imóvel</p>
                                                            </div>
                                                            <div
                                                                class="col-12 col-md-12 col-lg-2 align-self-center text-muted pad-content-list">
                                                                <p class="text-truncate title-legend-list">N˚ apto</p>
                                                            </div>
                                                            <div
                                                                class="col-auto col-md-auto col-lg-2 align-self-center pad-content-list">
                                                                <p class="text-truncate title-legend-list">Parcela</p>
                                                            </div>
                                                            <div
                                                                class="col-8 col-md-4 col-lg-2 align-self-center text-muted pad-content-list">
                                                                <p class="text-truncate title-legend-list">Data de
                                                                    vencimento</p>
                                                            </div>
                                                            <div
                                                                class="col-12 col-md-6 col-lg-2 align-self-center pad-content-list">
                                                                <p class="text-truncate title-legend-list">Valor</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    class="col-2 col-md-2 col-lg-auto align-self-center text-right ml-auto pr-0 order-4">
                                                    &nbsp;</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-for="(billing, index) in billings" :key="index">
                                <div class="bg-white" style="border-radius: 15px;">
                                    <div class="col-12 p-0 align-self-center">
                                        <div class="col-12">
                                            <div class="row">
                                                <div
                                                    class="col-3 col-md-auto col-lg-1 align-self-center text-left order-2 order-md-2 order-lg-2 pad-content-list-img">
                                                    <img src="../../assets/img/users/widget-table-pic1.jpg" alt="user"
                                                        class="rounded-circle card-body-list-img" />
                                                </div>
                                                <div
                                                    class="col-6 col-md-8 col-lg-9 px-0 py-2 order-3 align-self-center">
                                                    <div class="col-12">
                                                        <div class="row">
                                                            <div
                                                                class="col-12 col-md-12 col-lg-4 align-self-center pad-content-list">
                                                                <h6 class="card-body-list-title">
                                                                    {{ billing.descricaoEmpresa }}</h6>
                                                            </div>
                                                            <div
                                                                class="col-12 col-md-12 col-lg-2 align-self-center pad-content-list">
                                                                <h6 class="card-body-list-title">{{ billing.instrucao }}
                                                                </h6>
                                                            </div>
                                                            <div
                                                                class="col-auto col-md-auto col-lg-2 align-self-center pad-content-list">
                                                                <h6 class="torre">{{ billing.obraParcela }}</h6>
                                                            </div>
                                                            <div
                                                                class="col-8 col-md-4 col-lg-2 align-self-center pad-content-list">
                                                                <h6 class="apto">{{ billing.dataVencimento }}</h6>
                                                            </div>
                                                            <div
                                                                class="col-12 col-md-6 col-lg-2 align-self-center text-muted pad-content-list">
                                                                <h6 class="empreendi">{{ billing.valorDocumento }}</h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-3 col-md-auto col-lg-auto align-self-center text-right ml-auto order-4">
                                                    <!--<a class="btn btn-success btn-circle font-16 card-body-list-cta"
                                                        :href="billing.linhaDigitavel"><i
                                                            class="far fa-file-pdf"></i></a>-->
                                                    <input v-on:focus="$event.target.select()" type="hidden"
                                                        ref="digitableLine" id="code" :value="billing.linhaDigitavel" />
                                                    <button
                                                        class="btn btn-orange rounded-circle btn-circle font-16 card-body-list-cta"
                                                        @click="copy()"><i class="fas fa-barcode"></i></button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 pt-4">
                            <ul class="pagination float-right">
                                <li class="page-item disabled">
                                    <a class="page-link" href="#" tabindex="-1">Anterior</a>
                                </li>
                                <li class="page-item active"><a class="page-link" href="#">1</a></li>
                                <li class="page-item">
                                    <a class="page-link" href="#">2 <span class="sr-only">(current)</span></a>
                                </li>
                                <li class="page-item"><a class="page-link" href="#">3</a></li>
                                <li class="page-item">
                                    <a class="page-link" href="#">Próximo</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </template>

            <template v-if="showSelect">
                <div class="row">
                    <div class="col-12 m-5">
                        <select class="form-control" v-model="selected">
                            <option disabled value="">Selecione uma Obra</option>
                            <option v-for="company in personCompany" :key="company.id" :value="JSON.stringify(company)">
                                {{ company.DescricaoObra }} - Obra({{ company.Obra }})
                            </option>
                        </select>
                    </div>
                </div>
            </template>

            <template v-if="showExtract">
                {{extract}}
            </template>

            <template v-if="showReport">
                {{report}}
            </template>

        </div>
    </div>
</template>
  <script>
import EnterpriseService from "@/services/resources/Uau/EnterpriseService";
import BillingsAvailableForGenerate from "@/services/resources/Uau/BillingsAvailableForGenerate";
import BillingAvailableGenerate from "@/services/resources/Uau/BillingAvailableGenerate";
import PaymentStatementGenerate from "@/services/resources/Uau/PaymentStatementGenerate";

const enterprise = EnterpriseService.build();
const billingsAvailable = BillingsAvailableForGenerate.build();
const billingGenerate = BillingAvailableGenerate.build();
const PaymentStatement = PaymentStatementGenerate.build();

  export default {
      name: "Financial",
      data() {
          return {
              enterprises: [],
              billings_available: []
          };
      },
      watch:{
        "selected"(value){
             axios
                .post(this.prefix + "http://cityinc.ddns.net:81/uauAPI/api/v1.0/ExtratoDoCliente/GerarPDFExtratoCliente",
                    {
                        "empresa": JSON.parse(value).Empresa,
                        "obra": JSON.parse(value).Obra,
                        "num_venda": JSON.parse(value).Venda,
                        "tipo_ordenacao": 0,
                        "valor_antecipado": true,
                        "data_prorrogacao": true,
                        "ocultar_personalizacao": false,
                        "ocultar_usuario": false
                    },
                    {
                        headers: {
                            "Content-Type": "application/json",
                            "X-INTEGRATION-Authorization": process.env.VUE_APP_TOKEN_INTEGRATION_UAU,
                            "Authorization": this.tokenUser
                        }
                    })
                .then((res) => {
                    this.extract = res.data;
                    if(this.extract) {
                        this.showExtract = true;
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        }
      },
      created() {
          this.userData = this.$store.getters.getProfile;
  
          this.authenticateUser();
      },
      methods: {
          copy() {
          
              try {
                
                this.$refs.digitableLine.focus();
                document.execCommand('copy');

                  this.$bvToast.toast('Copiado!', {
                      title: 'Código do boleto copiado.',
                      autoHideDelay: 5000,
                      type: 'success'
                  })
              } catch (err) {
                  this.$bvToast.toast('Erro ao copiar.', {
                      title: 'Não foi possível copiar o código do boleto.',
                      autoHideDelay: 5000,
                      type: 'error'
                  })
              }
          },
          getEnterprises() {
            
            enterprise.get('').then(response => {
                this.enterprises = response;
            });

          },
          getBillingsAvailable() {

            billingsAvailable.get('').then(response => {
                this.billings_available = response;
            })

          },
          getBillingPdf(bank_code, id) {
            
            let params = {
                bank_code
            }

            billingGenerate.download(params, '.pdf', id);
          },
          getStatementPdf(company_id, construction_id, sale_id) {
            
            let params = {
                company_id,
                construction_id,
                sale_id
            }

            billingGenerate.download(params, '.pdf');
          }
      },
      mounted() {
        (function(h,o,t,j,a,r){
        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
        h._hjSettings={hjid:4932490,hjsv:6};
        a=o.getElementsByTagName('head')[0];
        r=o.createElement('script');r.async=1;
        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
        a.appendChild(r);
     })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
        this.getEnterprises();
        this.getBillingsAvailable();
      }
  };
  </script>