import Rest from '@/services/Rest';

/**
 * @typedef {BillingsAvailableForGenerate}
 */
export default class BillingsAvailableForGenerate extends Rest {
    /**
     * @type {String}
     */
    static resource = '/uau/billing-avalilable-for-generate'
}
